import React from 'react';
import { Switch, Divider, Button } from 'antd';
import { useFeatureFlag } from 'feature-flags.jsx';
import FormField from 'components/FormField';

function FeatureFlags() {
    const [enableUnsplash, setEnableUnsplash] = useFeatureFlag(
        'ENABLE_UNSPLASH',
    );
    const [enableShutterstock, setEnableShutterstock] = useFeatureFlag(
        'ENABLE_SHUTTERSTOCK',
    );
    const [enableMapTab, setEnableMapTab] = useFeatureFlag('ENABLE_MAP_TAB');

    return (
        <>
            <Divider orientation="left" style={{ width: 100 }}>
                Feature flags
            </Divider>
            <FormField label="Enable Unsplash">
                <Switch checked={enableUnsplash} onChange={setEnableUnsplash} />
            </FormField>
            <FormField label="Enable Shutterstock">
                <Switch
                    checked={enableShutterstock}
                    onChange={setEnableShutterstock}
                />
            </FormField>

            <FormField label="Enable Map Tab">
                <Switch checked={enableMapTab} onChange={setEnableMapTab} />
            </FormField>

            <Button
                onClick={
                    // @ts-ignore
                    // eslint-disable-next-line no-undef
                    () => window.methodDoesNotExist()
                }
            >
                Trigger error
            </Button>
        </>
    );
}

export default FeatureFlags;
