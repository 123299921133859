import React, { Suspense, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Card, Typography, notification } from 'antd';
import { getCurrentGuide } from 'store/guide-editor/selectors';
import Loading from 'components/Loading';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { patchGuideSingleField } from 'store/guide-editor/actions';
import { useAllCollectionSpotsFromGuide } from 'api/collection-spots.api';
import { parse } from 'utils/error-parser';

const CMSMap = React.lazy(() => import('components/CMSMap'));
const { Text } = Typography;

function GuideEditorSettings() {
    const dispatch = useDispatch();
    const guide = useSelector(getCurrentGuide);
    const [isEditingViewport, setIsEditingViewport] = useState(false);
    /** @type {[GeoJSON.Polygon, React.Dispatch<GeoJSON.Polygon>]} */
    const [newArea, setNewArea] = useState(null);
    const [isSavingArea, setIsSavingArea] = useState(false);
    const { spots } = useAllCollectionSpotsFromGuide(guide?.id);
    const viewportArea = guide?.viewport_area;

    if (!guide) {
        return null;
    }

    const onSaveArea = async () => {
        setIsSavingArea(true);
        try {
            await dispatch(
                patchGuideSingleField('viewport_area', JSON.stringify(newArea)),
            );
            setIsEditingViewport(false);
            setNewArea(null);
        } catch (e) {
            notification.error({
                message: 'Error saving changes.',
                description: parse(e),
            });
        }
        setIsSavingArea(false);
    };

    const noBBMessage =
        "Guide still doesn't have a bounding box for the map. Please reselect the location to automatically generate it.";

    return (
        <>
            <Card title="Map" style={{ maxWidth: 712 }}>
                {guide.viewport_area && (
                    <div className="guide-editor-settings__map-container">
                        <div className="guide-editor-settings__map-preview">
                            <Suspense fallback={<Loading />}>
                                <CMSMap
                                    isEditing={isEditingViewport}
                                    highlightedArea={viewportArea}
                                    onAreaChanged={(newArea) =>
                                        setNewArea(newArea)
                                    }
                                    collectionSpots={spots}
                                />
                            </Suspense>
                        </div>
                        <div className="grow-full-flex guide-editor-settings__map-text ml-ml">
                            <div className="guide-editor-settings__header">
                                Area of interest
                            </div>
                            <div className="mb-s"></div>
                            <Text type="secondary">
                                By editing the area of interest, you control
                                which part of the map is visible in the Guide
                                overview page in the Polarsteps app.
                            </Text>
                            <div className="mb-xs"></div>
                            <Text type="secondary">
                                You can position the map and adjust the blue
                                area. The map in the app will automatically
                                adjust to include all the spots you&apos;ve
                                &quot;covered&quot; with the blue area.
                            </Text>
                            <div className="grow-full-flex"></div>
                            {!isEditingViewport && (
                                <Button
                                    size="small"
                                    icon={<EditOutlined />}
                                    onClick={() => setIsEditingViewport(true)}
                                >
                                    Edit
                                </Button>
                            )}
                            {isEditingViewport && (
                                <div className="line">
                                    <Button
                                        size="small"
                                        type="primary"
                                        loading={isSavingArea}
                                        icon={<SaveOutlined />}
                                        onClick={() => onSaveArea()}
                                    >
                                        Save changes
                                    </Button>
                                    <Button
                                        className="ml-m"
                                        size="small"
                                        disabled={isSavingArea}
                                        onClick={() => {
                                            setIsEditingViewport(false);
                                            setNewArea(null);
                                        }}
                                    >
                                        Discard changes
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {!guide.viewport_area && (
                    <Alert message={noBBMessage} showIcon type="warning" />
                )}
            </Card>
        </>
    );
}

export default GuideEditorSettings;
