import React from 'react';
import classNames from 'classnames';
import { Input, Button, Modal, Typography, Card } from 'antd';
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import MediaSelector from 'components/MediaSelector';
import FormField from 'components/FormField';
import { STORY_COVER_RATIO } from 'constants/media-sizes.constants';
import Title from 'components/Typography/Title';
import InGuideSpotSelector from 'components/InGuideSpotSelector';
import './style.css';

const { confirm } = Modal;
const { Text } = Typography;
const { TextArea } = Input;

/**
 *
 * @param {Object} props
 * @param {StoryItem} props.story
 * @param {number} props.totalStories
 * @param {Guide} props.guide
 * @param {(storyId: Id, fieldName: string, newValue: any) => void} props.onFieldChanged
 * @param {(storyId: Id) => void} props.onMoveUp
 * @param {(storyId: Id) => void} props.onMoveDown
 * @param {(storyId: Id) => void} props.onRemove
 * @param {number} props.index
 */
function StoryItemEditor({
    story,
    totalStories,
    guide,
    onFieldChanged,
    onMoveUp,
    onMoveDown,
    onRemove,
    index,
}) {
    const showConfirm = () => {
        confirm({
            title: 'Delete story item?',
            content:
                'Are you sure you want to remove this story item? All data will be lost.',
            onOk() {
                onRemove(story.id);
            },
            okText: 'Delete story item',
            okButtonProps: {
                danger: true,
            },
            cancelText: 'Cancel',
        });
    };

    return (
        <Card>
            <div
                className={classNames(
                    'js-story-item-editor',
                    'story-item-editor',
                    'line-center',
                    {
                        'is-first-story': index === 0,
                    },
                )}
            >
                <div className="story-item-editor__media">
                    <MediaSelector
                        location={guide.location}
                        guideId={story.guide_id}
                        spot={story.spot}
                        includeVideos={true}
                        onSelect={(media) =>
                            onFieldChanged(story.id, 'cms_media', media)
                        }
                        onDelete={() =>
                            onFieldChanged(story.id, 'cms_media', null)
                        }
                        media={story.cms_media}
                        ratio={STORY_COVER_RATIO}
                    ></MediaSelector>
                </div>
                <div className="grow-full-flex story-item-editor__form">
                    <Title type="subsection">Story item {index + 1}</Title>
                    {index === 0 && !story.tagline && !story.spot && (
                        <>
                            <div className="mt-s"></div>
                            <Text type="secondary">
                                The first story item doesn’t contain tagline or
                                a spot
                            </Text>
                        </>
                    )}
                    {index === 0 && (story.tagline || story.spot) && (
                        <Text className="ml-xs" type="danger">
                            The first story item should not contain tagline or a
                            spot, please remove it. This tagline or spot WILL
                            NOT be shown in the guide.
                        </Text>
                    )}
                    {(index !== 0 || story.tagline || story.spot) && (
                        <div className="story-item-editor__form-inner">
                            <FormField
                                label="Tagline"
                                length={story.tagline.length}
                                maxLength={100}
                                labelWidth={50}
                            >
                                <TextArea
                                    className="form-field__textarea"
                                    autoSize={{ minRows: 1, maxRows: 4 }}
                                    value={story.tagline}
                                    onChange={(e) =>
                                        onFieldChanged(
                                            story.id,
                                            'tagline',
                                            e.target.value,
                                        )
                                    }
                                    placeholder="Add tagline"
                                />
                            </FormField>

                            <FormField label="Spot" labelWidth={50}>
                                <InGuideSpotSelector
                                    value={story.spot}
                                    guide={guide}
                                    onSelect={(spot) =>
                                        onFieldChanged(story.id, 'spot', spot)
                                    }
                                />
                            </FormField>
                            <FormField label="Notes" labelWidth={50}>
                                <TextArea
                                    className="form-field__textarea"
                                    autoSize={{ minRows: 1, maxRows: 12 }}
                                    value={story.editor_notes}
                                    onChange={(e) =>
                                        onFieldChanged(
                                            story.id,
                                            'editor_notes',
                                            e.target.value,
                                        )
                                    }
                                    placeholder="Add your thoughts..."
                                />
                            </FormField>
                        </div>
                    )}
                </div>
                {index !== 0 && (
                    <div className="story-item-editor__buttons">
                        <Button
                            disabled={story.order <= 1}
                            icon={<ArrowUpOutlined />}
                            onClick={() => onMoveUp(story.id)}
                        />
                        <Button
                            icon={
                                <DeleteOutlined style={{ color: '#F5222D' }} />
                            }
                            onClick={showConfirm}
                        />
                        <Button
                            icon={<ArrowDownOutlined />}
                            disabled={story.order >= totalStories - 1}
                            onClick={() => onMoveDown(story.id)}
                        />
                    </div>
                )}
            </div>
        </Card>
    );
}

export default StoryItemEditor;
