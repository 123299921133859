import React, { useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { Button, notification, Alert, Modal, Switch, Tooltip } from 'antd';
import { useUpdateEffect } from 'react-use';
import { DeleteOutlined } from '@ant-design/icons';
import { Tabs } from 'components/Tabs';
import Title from 'components/Typography/Title';
import { hasLocationInfo } from 'utils/ps-spot-utils';
import CollectionSpotEditorTips from './Tips/index';
import CollectionSpotEditorDetails from './Details';
import CollectionSpotEditorContent from './Content';
import {
    canCollectionSpotBeSaved,
    getReasonForCollectionSpotDisallowedSave,
} from 'utils/collection-spot-utils';
import { isSameExternalMedia } from 'utils/media-utils';
import { getNonBoughtMediaFromList } from 'utils/stock-images-utils';
import AddStockPhotoWarningModal from 'components/StockPhotoWarning';
import { SPOT_MEDIA } from 'constants/media-sizes.constants';
import { addCMSMediaIfChanged } from 'api/cms-media.api';
import {
    patchCollectionSpotAndTips,
    removeCollectionSpot,
    useCollectionSpots,
} from 'api/collection-spots.api';
import { parse } from 'utils/error-parser';
import CollectionSpotEditorPhotos from './Photos';
import './style.css';
import { format, parseISO } from 'date-fns';

const { confirm } = Modal;
/**
 * @param {Object} props
 * @param {CollectionSpot} props.originalCollectionSpot
 * @param {() => void} props.onClose
 * @param {() => void} [props.onDelete]
 * @param {boolean} props.visible
 * @param {Guide} props.guide
 * @param {boolean} [props.shouldShowImageSelectorFirst = false] If true, when the editor becomes visible
 * the details tab will be open and the image selector modal as well
 */
function CollectionSpotEditor({
    originalCollectionSpot,
    onClose,
    onDelete = () => undefined,
    visible,
    shouldShowImageSelectorFirst = false,
    guide,
}) {
    const { collectionSpots: allCollectionSpots } = useCollectionSpots(
        originalCollectionSpot.collection_id,
    );
    const [currentCollectionSpot, setCurrentCollectionSpot] = useState(
        cloneDeep(originalCollectionSpot),
    );
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('tips');
    useEffect(() => {
        if (visible && shouldShowImageSelectorFirst) {
            setActiveTab('photos');
        }
        if (visible && !shouldShowImageSelectorFirst) {
            setActiveTab('tips');
        }
    }, [visible, shouldShowImageSelectorFirst]);
    const [
        isAddingStockPhotoWarningShown,
        setIsAddingStockPhotoWarningShown,
    ] = useState(false);
    const [isAddingPhoto, setIsAddingPhoto] = useState(false);
    const [isDeletingSpot, setIsDeletingSpot] = useState(false);

    // When we detect the original has lost the featured photo, it means a migration happened.
    // TODO: avoid using this weird logic. We should always update the current one when the original is updated, or at least mark
    // as if there were changes. We could use useSWR for this.
    useUpdateEffect(() => {
        setCurrentCollectionSpot((lastCurrent) => {
            if (
                lastCurrent?.featured_photo &&
                !originalCollectionSpot?.featured_photo
            ) {
                // Photo has been removed, we can remove it also
                return {
                    ...lastCurrent,
                    featured_photo: null,
                    featured_photo_id: null,
                };
            }
            return lastCurrent;
        });
    }, [originalCollectionSpot]);

    const onPatchField = (changes) => {
        const newCurrentCollectionSpot = {
            ...currentCollectionSpot,
            ...changes,
        };
        setCurrentCollectionSpot(newCurrentCollectionSpot);
        if (changes.featured_photo && shouldShowImageSelectorFirst) {
            saveSpot(newCurrentCollectionSpot);
        }
    };

    /**
     * Checks if the guide is published, and the editor is adding spots with stock photos that will be bought.
     *
     * @param {CollectionSpot} newCurrentCollectionSpot
     * @returns {Promise<boolean>} When it returns true, the warning has been accepted, or non necessary.
     */
    const shouldShowWarningAddingStockPhotos = async (
        newCurrentCollectionSpot,
    ) => {
        if (guide.publish_status.name !== 'published') {
            return false;
        }
        const prevMedia = originalCollectionSpot.featured_photo;
        const currentMedia = newCurrentCollectionSpot.featured_photo;
        if (isSameExternalMedia(prevMedia, currentMedia)) {
            // Editor is not changing the photo
            return false;
        }
        const allNonBoughtStockImages = await getNonBoughtMediaFromList([
            currentMedia,
        ]);
        return !!allNonBoughtStockImages.length;
    };

    /**
     * @param {CollectionSpot} newCurrentCollectionSpot
     * @param {CMSMedia} newCover
     */
    const dispatchPatch = async (newCurrentCollectionSpot, newCover) => {
        try {
            setIsLoading(true);
            await patchCollectionSpotAndTips(
                { ...newCurrentCollectionSpot, order: undefined }, // We ignore whatever order we had, since that can't change inside of the editor
                originalCollectionSpot,
                newCover,
            );
            notification.success({
                message: 'Spot saved!',
            });
            setIsLoading(false);
            onClose();
        } catch (error) {
            notification.error({
                message: 'Error saving spot',
                description: parse(error),
            });
            setIsLoading(false);
        }
    };

    /**
     * @param {CollectionSpot} newCurrentCollectionSpot
     */
    const saveSpot = async (newCurrentCollectionSpot) => {
        const showWarning = await shouldShowWarningAddingStockPhotos(
            newCurrentCollectionSpot,
        );
        if (showWarning) {
            setIsAddingStockPhotoWarningShown(true);
            return;
        }
        setIsAddingPhoto(true);
        let newMedia;
        try {
            newMedia = await addCMSMediaIfChanged(
                newCurrentCollectionSpot.featured_photo,
                originalCollectionSpot.featured_photo,
                SPOT_MEDIA,
            );
        } catch (e) {
            notification.error({
                message: 'Error adding image',
                description: parse(e),
            });
            return;
        } finally {
            setIsAddingPhoto(false);
        }
        dispatchPatch(newCurrentCollectionSpot, newMedia);
    };

    const canSpotBeSaved = canCollectionSpotBeSaved(currentCollectionSpot);
    const reasonSpotCantBeSaved = getReasonForCollectionSpotDisallowedSave(
        currentCollectionSpot,
    );

    if (!currentCollectionSpot) {
        return null;
    }
    if (!currentCollectionSpot.spot) {
        return (
            <div className="collection-spot-editor__error">
                <Alert
                    message="The original spot has been deleted"
                    showIcon
                    type="error"
                />
            </div>
        );
    }

    const deleteCollectionSpot = async () => {
        setIsDeletingSpot(true);
        try {
            await removeCollectionSpot(
                originalCollectionSpot,
                allCollectionSpots,
            );
            onDelete();
        } catch (error) {
            notification.error({
                message: 'Error removing spot',
                description: parse(error),
            });
            setIsDeletingSpot(false);
        }
    };

    const showConfirmDelete = () => {
        confirm({
            title: 'Delete spot from collection?',
            content:
                'Are you sure you want to delete this spot from the collection? All tips will be removed as well.',
            onOk() {
                deleteCollectionSpot();
            },
            okButtonProps: { danger: true },
            okText: 'Delete spot',
            cancelText: 'Cancel',
        });
    };

    const tabsConfig = [
        {
            name: 'Tips',
            key: 'tips',
            content: (
                <CollectionSpotEditorTips
                    collectionSpot={currentCollectionSpot}
                    onPatchField={onPatchField}
                />
            ),
        },
        {
            name: 'Details',
            key: 'details',
            content: (
                <CollectionSpotEditorDetails
                    collectionSpot={currentCollectionSpot}
                    guide={guide}
                    onPatchField={onPatchField}
                />
            ),
        },
        {
            name: 'Content',
            key: 'content',
            content: (
                <CollectionSpotEditorContent
                    collectionSpot={currentCollectionSpot}
                    onPatchField={onPatchField}
                />
            ),
        },
        {
            name: 'Photos',
            key: 'photos',
            content: (
                <CollectionSpotEditorPhotos
                    collectionSpot={currentCollectionSpot}
                    guide={guide}
                    onPatchField={onPatchField}
                    visible={activeTab === 'photos'}
                />
            ),
        },
    ];

    return (
        <div className="collection-spot-editor">
            <div className="collection-spot-editor__title">
                <Title type="subsection">{originalCollectionSpot.name}</Title>
                <div className="ml-m collection-spot-editor__last-update">
                    Last Update:{' '}
                    {(originalCollectionSpot.last_modified &&
                        format(
                            parseISO(originalCollectionSpot.last_modified),
                            'MMM d, y',
                        )) ||
                        'NA'}
                </div>

                <div className="collection-spot-editor__title-publish">
                    Published
                </div>
                <Switch
                    defaultChecked={currentCollectionSpot.published}
                    onChange={(value) => onPatchField({ published: value })}
                />
                {!hasLocationInfo(currentCollectionSpot.spot) && (
                    <Alert
                        message="This spot does not have coordinates!"
                        description="Some things, like finding media for it, won't work."
                        showIcon
                        type="error"
                    />
                )}
            </div>
            <div className="collection-spot-editor__content">
                <Tabs
                    activeKey={activeTab}
                    onChange={setActiveTab}
                    tabs={tabsConfig}
                ></Tabs>
            </div>
            {isAddingStockPhotoWarningShown && (
                <AddStockPhotoWarningModal
                    photosToPurchase={[currentCollectionSpot.featured_photo]}
                    onAdd={(purchasedPhotos) => {
                        setIsAddingStockPhotoWarningShown(false);
                        dispatchPatch(
                            currentCollectionSpot,
                            purchasedPhotos[0],
                        );
                    }}
                    onCancel={() => {
                        setIsAddingStockPhotoWarningShown(false);
                    }}
                    sizesToUpload={SPOT_MEDIA}
                />
            )}
            <div className="collection-spot-editor__footer">
                <Button
                    type="link"
                    danger={true}
                    icon={<DeleteOutlined />}
                    onClick={() => showConfirmDelete()}
                    loading={isDeletingSpot}
                >
                    Delete spot
                </Button>
                <div className="grow-full-flex"></div>
                <div className="collection-spot-editor__footer-review">
                    Ready for review
                </div>
                <Switch
                    defaultChecked={currentCollectionSpot.ready_for_review}
                    onChange={(value) =>
                        onPatchField({ ready_for_review: value })
                    }
                />

                <Button size="large" onClick={onClose} className="ml-m mr-s">
                    Cancel
                </Button>
                <Tooltip title={reasonSpotCantBeSaved}>
                    <Button
                        size="large"
                        type="primary"
                        loading={isLoading || isAddingPhoto}
                        disabled={!canSpotBeSaved}
                        onClick={() => saveSpot(currentCollectionSpot)}
                    >
                        Save changes
                    </Button>
                </Tooltip>
            </div>
        </div>
    );
}

export default CollectionSpotEditor;
