import * as actionTypes from './action-types';

/**
 * @type {StoryItemsState}
 */
const STORY_ITEMS_INITIAL_STATE = {
    byGuideId: {},
    isSavingStoryItems: false,
};

/**
 * storyItems reducer
 *
 * @param {StoryItemsState} state
 * @param {*} action
 * @returns {StoryItemsState}
 */
export const storyItems = (state = STORY_ITEMS_INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case actionTypes.STARTED_LOADING_STORY_ITEMS_FOR_GUIDE:
            return {
                ...state,
                byGuideId: {
                    ...state.byGuideId,
                    [action.guideId]: {
                        loading: true,
                        error: null,
                        byId: null,
                    },
                },
            };
        case actionTypes.FAILED_LOADING_STORY_ITEMS_FOR_GUIDE:
            return {
                ...state,
                byGuideId: {
                    ...state.byGuideId,
                    [action.guideId]: {
                        loading: false,
                        error: action.error,
                        byId: null,
                    },
                },
            };

        case actionTypes.SUCCESS_LOADING_STORY_ITEMS_FOR_GUIDE:
            return {
                ...state,
                byGuideId: {
                    ...state.byGuideId,
                    [action.guideId]: {
                        loading: false,
                        error: null,
                        byId: getHashFromStoryItemsArray(action.payload),
                    },
                },
            };

        case actionTypes.STARTED_SAVING_STORY_ITEMS:
            return {
                ...state,
                isSavingStoryItems: true,
            };
        case actionTypes.SUCCESS_SAVING_STORY_ITEMS:
        case actionTypes.FAILED_SAVING_STORY_ITEMS:
            return {
                ...state,
                isSavingStoryItems: false,
            };

        default:
            return state;
    }
};

/**
 *
 * @param {StoryItem[]} itemsArray
 * @returns {Object.<Id, StoryItem>}
 */
function getHashFromStoryItemsArray(itemsArray = []) {
    return itemsArray.reduce((accumulator, storyItem) => {
        return {
            ...accumulator,
            [storyItem.id]: storyItem,
        };
    }, {});
}
