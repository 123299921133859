export const GUIDE_TAGLINE_MAX_LENGTH = 40;
export const GUIDE_QUICK_FACT_TITLE_MAX_LENGTH = 30;
export const GUIDE_QUICK_FACT_BODY_MAX_LENGTH = 50;
export const GUIDE_DESCRIPTION_MAX_LENGTH = 200;
export const GUIDE_CUSTOM_LOCATION_MAX_LENGTH = 30;

export const COLLECTION_SPOT_NAME_MAX_LENGTH = 50;
export const COLLECTION_SPOT_LOCAL_NAME_MAX_LENGTH = 50;

export const TRANSLATION_TIP_MAX_LENGTH = 130;
export const CUSTOM_TIP_MAX_LENGTH = 130;

export const QUICK_FACTS_PER_GUIDE = 3;
