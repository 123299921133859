import React, { useEffect, useState } from 'react';
import { Spin, Alert } from 'antd';
import PolarstepsMediaSelector from './Polarsteps/PolarstepsMedia';
import FavoriteMediaSelector from './FavoriteMedia';
import SpotMediaSelector from './Spot/SpotMedia';
import UnsplashMediaSelector from './Unsplash/UnsplashMedia';
import ShutterstockMediaSelector from './Shutterstock/ShutterstockMedia';
import UploadedPhotosMediaSelector from './UploadedPhotos/UploadedPhotosMedia';
import { useFeatureFlag } from 'feature-flags';
import MediaSelectorSourceSelector, {
    isSpotSourceWithPhotos,
    MEDIA_SOURCES,
} from './MediaSelectorSourceSelector.jsx';
import { useSpotDetails } from 'api/spots.api';

/**
 * @param {Object} props
 * @param {CMSMedia} props.selectedMedia
 * @param {Id} props.guideId
 * @param {PSLocation?} props.location
 * @param {PSSpot_v15_Minimal?} props.spot
 * @param {CollectionSpot?} props.collectionSpot
 * @param {boolean} props.includeVideos
 * @param {(media: CMSMedia) => void} props.onSelect
 * @param {boolean} props.shouldShow
 */
export default function MediaSelectorModal({
    selectedMedia,
    guideId,
    location,
    spot,
    collectionSpot,
    includeVideos,
    onSelect,
    shouldShow,
}) {
    const [ENABLE_UNSPLASH] = useFeatureFlag('ENABLE_UNSPLASH');
    const [ENABLE_SHUTTERSTOCK] = useFeatureFlag('ENABLE_SHUTTERSTOCK');
    const [selectedSource, setSelectedSource] = useState(
        MEDIA_SOURCES.POLARSTEPS,
    );
    const { isLoading, fullSpot, errorLoading } = useSpotDetails(spot);
    useEffect(() => {
        if (fullSpot && isSpotSourceWithPhotos(fullSpot.source)) {
            setSelectedSource(MEDIA_SOURCES.SPOT);
        } else {
            setSelectedSource(MEDIA_SOURCES.POLARSTEPS);
        }
    }, [fullSpot]);
    const containerStyle = { display: shouldShow ? 'block' : 'none' };

    if (isLoading) {
        return (
            <div className="media-selector-modal__loading">
                <Spin />
            </div>
        );
    }
    if (errorLoading) {
        return (
            <Alert
                message="Error loading details of spot"
                description={errorLoading}
                type="error"
            />
        );
    }
    const sourceSelector = (
        <MediaSelectorSourceSelector
            spot={fullSpot}
            value={selectedSource}
            onChange={setSelectedSource}
        />
    );

    // We hide and show using css so when switching sources the scroll is maintained
    return (
        <div style={containerStyle}>
            <PolarstepsMediaSelector
                selectedMedia={selectedMedia}
                guideId={guideId}
                location={location}
                onSelect={onSelect}
                includeVideos={includeVideos}
                sourceSelector={sourceSelector}
                isShown={selectedSource === MEDIA_SOURCES.POLARSTEPS}
            />
            {ENABLE_SHUTTERSTOCK && (
                <ShutterstockMediaSelector
                    selectedMedia={selectedMedia}
                    guideId={guideId}
                    onSelect={onSelect}
                    location={location}
                    sourceSelector={sourceSelector}
                    isShown={selectedSource === MEDIA_SOURCES.SHUTTERSTOCK}
                    spot={fullSpot}
                />
            )}
            {ENABLE_UNSPLASH && (
                <UnsplashMediaSelector
                    selectedMedia={selectedMedia}
                    guideId={guideId}
                    onSelect={onSelect}
                    location={location}
                    sourceSelector={sourceSelector}
                    isShown={selectedSource === MEDIA_SOURCES.UNSPLASH}
                    spot={fullSpot}
                />
            )}
            <FavoriteMediaSelector
                selectedMedia={selectedMedia}
                guideId={guideId}
                onSelect={onSelect}
                includeVideos={includeVideos}
                sourceSelector={sourceSelector}
                isShown={selectedSource === MEDIA_SOURCES.FAVORITES}
            />
            {fullSpot && (
                <SpotMediaSelector
                    selectedMedia={selectedMedia}
                    guideId={guideId}
                    onSelect={onSelect}
                    sourceSelector={sourceSelector}
                    isShown={selectedSource === MEDIA_SOURCES.SPOT}
                    spot={fullSpot}
                />
            )}
            {collectionSpot && (
                <UploadedPhotosMediaSelector
                    selectedMedia={selectedMedia}
                    guideId={guideId}
                    onSelect={onSelect}
                    sourceSelector={sourceSelector}
                    isShown={selectedSource === MEDIA_SOURCES.UPLOADED_PHOTOS}
                    collectionSpot={collectionSpot}
                />
            )}
        </div>
    );
}
