import React, { useContext, useMemo, useState } from 'react';

/**
 * @typedef {"ENABLE_UNSPLASH" | "ENABLE_SHUTTERSTOCK" | "ENABLE_MAP_TAB"} AllowedFeaturedFlags
 */

/** @type {FeaturedFlagsStore} */
const defaultFlags = {
    ENABLE_UNSPLASH: true,
    ENABLE_SHUTTERSTOCK: true,
    ENABLE_MAP_TAB: false,
};

/** @type {FeaturedFlagsStore} */
const initialFlags = {
    ENABLE_UNSPLASH: getInitialFlagValue('ENABLE_UNSPLASH'),
    ENABLE_SHUTTERSTOCK: getInitialFlagValue('ENABLE_SHUTTERSTOCK'),
    ENABLE_MAP_TAB: getInitialFlagValue('ENABLE_MAP_TAB'),
};

/**
 * @param {AllowedFeaturedFlags} flagName
 * @param {boolean} [useLocalStorage=true]
 */
function getInitialFlagValue(flagName, useLocalStorage = true) {
    if (!useLocalStorage) {
        return defaultFlags[flagName];
    }
    const localStorageValue = getFromLocalStorage(flagName);
    if (localStorageValue === null) {
        return defaultFlags[flagName];
    }
    return localStorageValue;
}

/**
 * @typedef {Object.<AllowedFeaturedFlags, boolean>} FeaturedFlagsStore
 */

const defaultValue = {
    featureFlags: initialFlags,
    /** @type {(newValue: FeaturedFlagsStore) => void} */
    setFeatureFlags: () => undefined,
};

const FeatureFlagsContext = React.createContext(defaultValue);

/**
 *
 * @param {AllowedFeaturedFlags} name
 * @returns {[boolean, (newValue: boolean) => void]}
 */
export const useFeatureFlag = (name) => {
    const { featureFlags, setFeatureFlags } = useContext(FeatureFlagsContext);
    const flagValue = featureFlags[name] || false;
    const setFlagValue = (newValue) => {
        setInLocalStorage(name, newValue);
        setFeatureFlags({
            ...featureFlags,
            [name]: newValue,
        });
    };

    return [flagValue, setFlagValue];
};

export const FeatureFlagsContextComponent = ({ children }) => {
    /** @type {[FeaturedFlagsStore, React.Dispatch<FeaturedFlagsStore>]} */
    const [featureFlags, setFeatureFlags] = useState(initialFlags);
    const value = useMemo(
        () => ({
            featureFlags,
            setFeatureFlags,
        }),
        [featureFlags, setFeatureFlags],
    );

    return (
        <FeatureFlagsContext.Provider value={value}>
            {children}
        </FeatureFlagsContext.Provider>
    );
};

/**
 *
 * @param {AllowedFeaturedFlags} flagName
 * @returns {boolean}
 */
function getFromLocalStorage(flagName) {
    try {
        const value = localStorage.getItem(`ff_${flagName}`);
        if (value === null || typeof value === 'undefined') {
            return null;
        }
        return value === 'true';
    } catch (e) {
        return undefined;
    }
}

/**
 * @param {AllowedFeaturedFlags} flagName
 * @param {boolean} value
 */
function setInLocalStorage(flagName, value) {
    try {
        localStorage.setItem(`ff_${flagName}`, value + '');
    } catch (e) {
        console.warn('localStorage not working', e);
    }
}
