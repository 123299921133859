import React, { useState } from 'react';
import { Modal, Typography } from 'antd';
import { CloseCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { parse } from 'utils/error-parser';
import { addCMSMedia } from 'api/cms-media.api';

const { Text } = Typography;

/**
 * @param {Object} props
 * @param {(cmsMedia: CMSMedia[]) => void} props.onAdd Callback when user has agreed and photo has been added correctly
 * @param {CMSMedia[]} props.photosToPurchase List of photos that needs to be purchased
 * @param {MediaSize[]} props.sizesToUpload Sizes to use if uploading the new photos
 * @param {() => void} props.onCancel
 */
function AddStockPhotoWarningModal({
    onAdd,
    onCancel,
    photosToPurchase,
    sizesToUpload,
}) {
    const [isAdding, setIsAdding] = useState(false);
    /** @type {[string, React.Dispatch<string>]} */
    const [error, setError] = useState(null);

    const purchase = async () => {
        setIsAdding(true);
        try {
            const allPhotos = await Promise.all(
                photosToPurchase.map((media) =>
                    addCMSMedia(media, sizesToUpload, true),
                ),
            );
            setError(null);
            setIsAdding(false);
            onAdd(allPhotos);
        } catch (e) {
            setError(parse(e));
            setIsAdding(false);
        }
    };
    const okText = getOkText(isAdding, !!error);
    const title = getTitle(photosToPurchase.length, !!error);
    const text = getText(photosToPurchase.length, error);

    return (
        <Modal
            open={true}
            okText={okText}
            onOk={() => purchase()}
            okButtonProps={{ loading: isAdding, disabled: isAdding }}
            onCancel={onCancel}
            closable={false}
            maskClosable={false}
        >
            <div className="line">
                {getIcon(!!error)}
                <div className="grow-full-flex ml-m">
                    <Text strong>{title}</Text>
                    <div className="mt-m">{text}</div>
                </div>
            </div>
        </Modal>
    );
}

function getIcon(error = false) {
    if (error) {
        return (
            <CloseCircleOutlined
                type="close-circle"
                style={{ color: '#FF4D4F', fontSize: 24 }}
            />
        );
    }
    return <WarningOutlined style={{ color: '#FAAD14', fontSize: 24 }} />;
}
function getOkText(isPurchasing = false, error = false) {
    if (isPurchasing) {
        return 'Purchasing photos';
    }
    if (error) {
        return 'Retry purchase & save';
    }

    return 'Purchase & save';
}

function getTitle(photosToPurchase = 0, error = false) {
    if (error) {
        return `Error purchasing ${photosToPurchase} stock photo(s)`;
    }
    return `Purchasing ${photosToPurchase} stock photo(s)`;
}

function getText(photosToPurchase = 0, error = '') {
    if (error) {
        return `Something went wrong. Error: “${error}”`;
    }
    return `By saving you’ll purchase ${photosToPurchase} stock photo(s). Are you sure?`;
}

export default AddStockPhotoWarningModal;
