import { notification } from 'antd';
import { cloneDeep } from 'lodash';
import {
    START_ADDING_GUIDE,
    STOP_ADDING_GUIDE,
    PATCH_GUIDE_FIELD,
    START_SAVING_NEW_GUIDE,
    SUCCESS_SAVING_NEW_GUIDE,
    FAILED_SAVING_NEW_GUIDE,
    START_PATCHING_GUIDE,
    SUCCESS_PATCHING_GUIDE,
    FAILED_PATCHING_GUIDE,
    INIT_EDITED_GUIDE,
    STOP_EDITING_GUIDE,
    NOTIFY_SPOT_COLLECTION_EDITOR_SHOWN,
    SET_CURRENT_GUIDE_STORIES,
    UPDATE_CURRENT_GUIDE_FIELD,
} from './action-types';

import {
    addGuide,
    patchGuide as patchGuideInDB,
    patchGuideSingleField as patchGuideSingleFieldInDB,
} from 'api/guides.api';
import { addLocation, removeLocation } from 'api/location.api';
import { parse, parseFetchError } from 'utils/error-parser';
import { getCurrentGuide, isGuideCorrect, getOriginalGuide } from './selectors';
import { getCurrentUser } from 'store/auth/selectors';
import { loadGuide } from 'store/guides/actions';
import {
    loadStoriesForGuide,
    saveChangedStories,
} from 'store/story-items/actions';
import { isSameObject } from 'utils/compare';
import { deleteOldCMSMediaIfChanged } from 'api/cms-media.api';
import { SUCCESS_LOADING_GUIDE, UPDATE_GUIDE } from 'store/guides/action-types';
import { getStoriesForGuide } from 'store/story-items/selectors';
import { getPolygonGeometryFromBBox } from 'utils/geojson-utils';

/**
 * Starts the edition of a new guide
 *
 * @returns {object} The action to be dispatched
 */
export const startAddingGuide = () => {
    return async (dispatch, getState) => {
        /** @type {RootState} */
        const state = getState();
        const currentUser = getCurrentUser(state);

        dispatch({
            type: START_ADDING_GUIDE,
            author: currentUser,
        });
    };
};

/**
 * Stops the edition of a new guide
 *
 * @returns {object} The action to be dispatched
 */
export const stopAddingGuide = () => {
    return {
        type: STOP_ADDING_GUIDE,
    };
};

/**
 * Starts the edition of an existing guide
 *
 * @param {Id} guideId
 * @returns {object} The action to be dispatched
 */
export const startEditingGuide = (guideId) => {
    return (dispatch) => {
        dispatch(loadGuide(guideId)).then((guide) => {
            dispatch({
                type: INIT_EDITED_GUIDE,
                guide,
            });
        });
    };
};

/**
 * Stops the edition of an existing guide
 *
 * @returns {object} The action to be dispatched
 */
export const stopEditingGuide = () => {
    return {
        type: STOP_EDITING_GUIDE,
    };
};

/**
 * Changes the value of a field in the currently edited guided
 *
 * @param {string} fieldName
 * @param {*} newValue
 * @returns {object} The action to be dispatched
 */
export const patchGuideField = (fieldName, newValue) => {
    return {
        type: PATCH_GUIDE_FIELD,
        fieldName,
        newValue,
    };
};

/**
 * Changes the location in the currently edited guided, updating its viewport fields if there is BoundingBox info
 *
 * @param {PSLocationWithBB} newLocation
 */
export const patchGuideLocation = (newLocation) => {
    return (dispatch) => {
        dispatch(patchGuideField('location', newLocation));
        if (newLocation.boundingbox) {
            const polygon = getPolygonGeometryFromBBox(newLocation.boundingbox);
            dispatch(patchGuideField('viewport_area', polygon));
            dispatch(patchGuideField('search_area', polygon));
        }
    };
};

/**
 * Saves a new guide (already should be in the state) in the DB
 *
 * @param {CMSMedia?} newCover
 * @returns {(dispatch: any, getState: any) => Promise<void>}
 */
export const saveNewGuide = (newCover) => {
    return async (dispatch, getState) => {
        /** @type {RootState} */
        const state = getState();
        if (!isGuideCorrect(state)) {
            return Promise.reject('Guide is not complete');
        }
        const currentUser = getCurrentUser(state);
        if (!currentUser) {
            return Promise.reject('Not logged in');
        }
        const guide = getCurrentGuide(state);

        dispatch({ type: START_SAVING_NEW_GUIDE });

        try {
            // We add the selected location and media if any
            const addedLocation = await addLocation(guide.location);
            await addGuide({
                ...guide,
                main_editor: guide.main_editor || currentUser,
                main_editor_id: guide.main_editor_id || currentUser.id,
                cover_image: newCover,
                location: addedLocation,
            });
            dispatch({ type: SUCCESS_SAVING_NEW_GUIDE });
        } catch (e) {
            const errorText = parse(e);
            dispatch({ type: FAILED_SAVING_NEW_GUIDE, error: errorText });
            notification.error({
                message: 'Error adding guide',
                description: errorText,
            });
            throw errorText;
        }
    };
};

/**
 * Patches the guide being edited
 *
 * @param {CMSMedia?} newCover
 * @returns {(dispatch: any, getState: any) => Promise<Guide>}
 */
export const patchGuide = (newCover) => {
    return async (dispatch, getState) => {
        /** @type {RootState} */
        const state = getState();
        if (!isGuideCorrect(state)) {
            return Promise.reject('Guide is not complete');
        }

        const guide = getCurrentGuide(state);
        const originalGuide = getOriginalGuide(state);

        dispatch({ type: START_PATCHING_GUIDE });

        try {
            const location = await addLocationIfChanged(
                guide.location,
                originalGuide.location,
            );
            const patchedGuide = await patchGuideInDB({
                ...guide,
                location,
                cover_image: newCover,
            });

            // Update stores accordingly
            dispatch({
                type: SUCCESS_LOADING_GUIDE,
                guideId: patchedGuide.id,
                payload: patchedGuide,
            });
            dispatch({ type: SUCCESS_PATCHING_GUIDE, patchedGuide });

            deleteOldLocationIfChanged(
                patchedGuide.location,
                originalGuide.location,
            );
            deleteOldCMSMediaIfChanged(
                patchedGuide.cover_image,
                originalGuide.cover_image,
            );
            return patchedGuide;
        } catch (error) {
            const errorText = await parseFetchError(error);
            dispatch({ type: FAILED_PATCHING_GUIDE, error: errorText });
            throw new Error(errorText);
        }
    };
};

/**
 * Patches only one field of the guide being edited
 *
 * @param {string} fieldName
 * @param {any} value
 */
export const patchGuideSingleField = (fieldName, value) => {
    return async (dispatch, getState) => {
        /** @type {RootState} */
        const state = getState();
        const originalGuide = getOriginalGuide(state);

        try {
            const patchedGuide = await patchGuideSingleFieldInDB(
                originalGuide,
                fieldName,
                value,
            );
            dispatch({ type: UPDATE_GUIDE, payload: patchedGuide });
            dispatch({
                type: UPDATE_CURRENT_GUIDE_FIELD,
                fieldName,
                value: patchedGuide[fieldName],
            });
            return patchedGuide;
        } catch (error) {
            const errorText = parse(error);
            return Promise.reject(errorText);
        }
    };
};

/**
 * Checks if two locations are the same, then saves the new one
 *
 * @param {PSLocation} newLocation
 * @param {PSLocation} oldLocation
 * @returns {Promise<PSLocation>} The newly saved location, if needed, the original if not needed.
 */
function addLocationIfChanged(newLocation, oldLocation) {
    if (isSameObject(newLocation, oldLocation)) {
        return Promise.resolve(newLocation);
    }
    return addLocation(newLocation);
}

/**
 * Checks if an old location has changed, and deletes it if so
 *
 * @param {PSLocation} newLocation
 * @param {PSLocation} oldLocation
 * @returns {Promise}
 */
function deleteOldLocationIfChanged(newLocation, oldLocation) {
    if (!oldLocation || isSameObject(newLocation, oldLocation)) {
        return Promise.resolve();
    }
    return removeLocation(oldLocation);
}

/**
 * Notifies that the spot collection editor is shown or not
 *
 * @param {boolean} isShown
 * @returns {object} The action to be dispatched
 */
export const notifySpotCollectionEditorShown = (isShown) => {
    return {
        type: NOTIFY_SPOT_COLLECTION_EDITOR_SHOWN,
        isShown,
    };
};

/**
 * Starts the edition of an existing guide
 *
 * @param {Id} guideId
 * @returns {object} The action to be dispatched
 */
export const initGuideStories = (guideId) => {
    return async (dispatch) => {
        const originalStories = await dispatch(loadStoriesForGuide(guideId));
        dispatch({
            type: SET_CURRENT_GUIDE_STORIES,
            currentStories: fixStoryOrder(cloneDeep(originalStories)),
        });
    };
};

/**
 * Saves the current guide stories being edited
 *
 * @param {Id} guideId
 * @param {StoryItem[]} currentStories If their cms_media has changed, it should be already uploaded and updated in this object,
 * only the reference to it will be updated in this action
 * @returns {object} The action to be dispatched
 */
export const saveGuideStories = (guideId, currentStories) => {
    return async (dispatch, getState) => {
        const originalStories = getStoriesForGuide(getState(), guideId);
        await dispatch(
            saveChangedStories(guideId, currentStories, originalStories),
        );
        await dispatch(initGuideStories(guideId));
        notification.success({
            message: 'The changes were saved successfully',
        });
    };
};

/**
 * Resets the stories being edited for a guide
 *
 * @returns {object} The action to be dispatched
 */
export const resetGuideStories = () => {
    return {
        type: SET_CURRENT_GUIDE_STORIES,
        currentStories: null,
    };
};

/**
 * Notifies the change in guide stories being edited
 *
 * @param {StoryItem[]} stories
 * @returns {object} The action to be dispatched
 */
export const notifyGuideStoriesChange = (stories) => {
    return {
        type: SET_CURRENT_GUIDE_STORIES,
        currentStories: fixStoryOrder(stories),
    };
};

/**
 * @param {StoryItem[]} stories
 * @returns {StoryItem[]}
 */
function fixStoryOrder(stories) {
    const orderedStories = stories.slice().sort((a, b) => a.order - b.order);
    return orderedStories.map((story, index) => {
        return {
            ...story,
            order: index,
        };
    });
}
