import { IMAGE, isImage, isVideo } from 'constants/media-types.constants';
import { getUser } from 'api/media.api';
import { getAllSources, getSourceId, isSpotSource } from 'api/sources.api';
import { getPhotoWithClosestSize } from 'utils/ps-spot-utils';
import { getDownloadOptionsFromMedia } from 'api/cms-media.api';
import { getContributor } from 'api/shutterstock.api';
import { drawImgToCanvas, loadUrlInImage } from './image/image-utils';

/**
 * Gets a text describing the media selected (Image or Video)
 *
 * @param {AnyMedia} media
 * @returns {string}
 */
export const getMediaTypeText = (media) => {
    if (!media || isImage(media)) {
        return 'Image';
    }
    return 'Video';
};

/**
 * Converts media from Polarsteps to CMSMedia
 *
 * @param {PSMedia} media
 * @returns {Promise<CMSMedia>}
 */
export const convertPSMediaToCMSMedia = async (media) => {
    const user = await getUser(media);
    const psSourceId = await getSourceId('polarsteps');
    return {
        source_id: psSourceId,
        paths: getPathsFromPSMedia(media),
        external_id: media.id + '',
        author_name: `${user.first_name} ${user.last_name}`,
        source_url: media.large_thumbnail_path,
        attribution_url: null,
        type: media.type,
        media_id: media.id,
    };
};

/**
 * @param {PSMedia} psMedia
 * @returns {Object.<string, any>}
 */
function getPathsFromPSMedia(psMedia) {
    if (isVideo(psMedia)) {
        return {
            video: { path: psMedia.cdn_path },
            thumbnail: { path: psMedia.large_thumbnail_path },
        };
    }

    return {
        default: { path: psMedia.large_thumbnail_path },
    };
}

/**
 * Finds the media size that is closer to the size we specify (min width) from a CMSMedia object
 *
 * @param {CMSMedia} media
 * @param {number} [minWidth=800]
 * @returns {CMSMediaPathInfo}
 */
export const getMediaItemWithClosestSize = (media, minWidth = 800) => {
    if (!media || !media.paths) {
        return null;
    }
    const sizeIds = Object.entries(media.paths)
        .map(([key, pathInfo]) => ({
            key,
            pathInfo,
            sizeInfo: getSizeInfo(key, pathInfo),
        }))
        .filter(({ sizeInfo }) => !!sizeInfo);

    const orderedSizes = sizeIds.sort((a, b) =>
        a.sizeInfo.width > b.sizeInfo.width ? 1 : -1,
    );
    // Now orderedSizedIds are all the keys with info, ordered from less to max width
    for (const sizeId of orderedSizes) {
        if (sizeId.sizeInfo.width >= minWidth) {
            return sizeId.pathInfo;
        }
    }
    // If nothing found, just return the biggest one
    if (orderedSizes.length) {
        return orderedSizes[orderedSizes.length - 1].pathInfo;
    }
    return null;
};

/**
 * Finds the media that is closer to the size we specify (min width) from a CMSMedia object
 *
 * @param {CMSMedia} media
 * @param {number} [minWidth=800]
 * @returns {CMSMediaPathInfo}
 */
export const getMediaWithSize = (media, minWidth = 800) => {
    if (!media || !media.paths) {
        return null;
    }
    if (isVideo(media)) {
        return getVideoPreview(media);
    }

    const firstFittingMedia = getMediaItemWithClosestSize(media, minWidth);

    if (firstFittingMedia) {
        return firstFittingMedia;
    }

    // We didn't find anything matching, we try to return the 'default'
    if (media.paths['default']) {
        return media.paths['default'];
    }

    // Fallback, return anything
    return Object.entries(media.paths)[0][1];
};

/**
 * Gets the image to preview a video
 *
 * @param {CMSMedia} media
 * @returns {CMSMediaPathInfo}
 */
function getVideoPreview(media) {
    return media.paths.thumbnail || media.paths.default || null;
}
/**
 * Finds the media that is bigger from a CMSMedia object
 *
 * @param {CMSMedia} media
 * @returns {CMSMediaPathInfo}
 */
export const getBiggestMedia = (media) => {
    return getMediaWithSize(media, Number.MAX_VALUE);
};

/**
 * Gets the size associated with a media path info
 *
 * @param {string} sizeId Key used to store the path info in the paths object
 * @param {CMSMediaPathInfo} pathInfo
 * @returns {Dimensions}
 */
export const getSizeInfo = (sizeId, pathInfo) => {
    if (pathInfo.height && pathInfo.width) {
        return { height: pathInfo.height, width: pathInfo.width };
    }
    const [width, height] = sizeId.split('_').map(parseFloat);
    if (!width || Number.isNaN(width) || !height || Number.isNaN(height)) {
        return null;
    }
    return {
        height,
        width,
    };
};

/**
 * Converts a size to and identifier for it. Centralized here to avoid confusion with the order (should be width_height)
 *
 * @param {{width: number, height: number}} size
 * @returns {string}
 */
export const getIdFromSize = (size) => {
    return `${size.width}_${size.height}`;
};

/**
 * Converts media from Spots to CMSMedia
 *
 * @param {PSSpot_v15} spot
 * @param {PSSpot_v15_Photo} media
 * @returns {Promise<CMSMedia>}
 */
export const convertSpotMediaToCMSMedia = async (spot, media) => {
    const spotSourceId = await getSourceId(spot.source);
    return {
        source_id: spotSourceId,
        paths: getPathsFromSpotMedia(media),
        external_id: media.external_id + '',
        author_name: `${spot.source || 'unknown'}`,
        source_url: spot.deeplink || spot.website || 'unknown',
        attribution_url: spot.deeplink || spot.website || 'unknown',
        type: IMAGE,
        media_id: null,
    };
};

/**
 * @param {PSSpot_v15_Photo} photo
 * @returns {Object.<string, CMSMediaPathInfo>}
 */
function getPathsFromSpotMedia(photo) {
    const biggestPath = getPhotoWithClosestSize(photo, 4000);

    if (!biggestPath) {
        throw new Error('getPathsFromSpotMedia: Couldnt find media');
    }
    const sizeId = getIdFromSize({
        width: biggestPath.width,
        height: biggestPath.height,
    });

    return {
        [sizeId]: {
            path: biggestPath.path,
            width: biggestPath.width,
            height: biggestPath.height,
        },
    };
}

/**
 * Converts a Unsplash search imageCMS media object we can use to add to a collection spot.
 *
 * @param {UnsplashPhotoFromSearch} unsplashPhoto
 * @returns {Promise<CMSMedia>}
 */
export const convertUnsplashMediaToCMSMedia = async (unsplashPhoto) => {
    const unsplashSourceId = await getSourceId('unsplash');
    return {
        source_id: unsplashSourceId,
        paths: getPathsFromUnsplashMedia(unsplashPhoto),
        external_id: unsplashPhoto.id + '',
        author_name: unsplashPhoto.user?.name || 'Unknown',
        source_url: unsplashPhoto.links.html,
        attribution_url: unsplashPhoto.user?.links.html,
        type: IMAGE,
        media_id: null,
    };
};

/**
 * @param {UnsplashPhotoFromSearch} photo
 * @returns {Object.<string, CMSMediaPathInfo>}
 */
function getPathsFromUnsplashMedia(photo) {
    const fullSizeId = getIdFromSize({
        width: photo.width,
        height: photo.height,
    });
    // "regular" size has 1080 px width
    const mediumSize = {
        width: 1080,
        height: Math.round((1080 * photo.height) / photo.width),
    };
    const mediumSizeId = getIdFromSize(mediumSize);
    return {
        [fullSizeId]: {
            path: photo.urls.raw,
            width: photo.width,
            height: photo.height,
        },
        [mediumSizeId]: {
            path: photo.urls.regular,
            ...mediumSize,
        },
    };
}

/**
 * Converts a Shutterstock search imageCMS media object we can use to add to a collection spot.
 *
 * @param {ShutterstockPhotoFromSearch} shutterstockPhoto
 * @returns {Promise<CMSMedia>}
 */
export const convertShutterstockMediaToCMSMedia = async (shutterstockPhoto) => {
    const shutterstockSourceId = await getSourceId('shutterstock');
    const contributor = await getContributor(shutterstockPhoto.contributor.id);

    return {
        source_id: shutterstockSourceId,
        paths: await getPathsFromShutterstockMedia(shutterstockPhoto),
        external_id: shutterstockPhoto.id + '',
        author_name: contributor.display_name,
        source_url: null,
        attribution_url: contributor.portfolio_url,
        type: IMAGE,
        media_id: null,
    };
};

/**
 * Gets the paths to images for Shutterstock media. It takes care of removing the possible white stripes in them,
 * in which case the path won't be a external URL but a base64 encoded data URI.
 *
 * @param {ShutterstockPhotoFromSearch} photo
 * @returns {Promise<Object.<string, CMSMediaPathInfo> >}
 */
async function getPathsFromShutterstockMedia(photo) {
    const assetsToUse = ['preview', 'preview_1000', 'preview_1500']
        .map((sizeId) => photo.assets[sizeId])
        .filter((asset) => !!asset?.url)
        .map((asset) => ({
            width: asset.width,
            height: asset.height,
            path: asset.url,
        }));

    const correctlySizedAssets = await Promise.all(
        assetsToUse.map(async (asset) => ({
            ...asset,
            path: await fixShutterstockImageToFitSize(asset),
        })),
    );

    return correctlySizedAssets.reduce((acc, value) => {
        return {
            ...acc,
            [getIdFromSize(value)]: value,
        };
    }, {});
}

/**
 * @param {CMSMediaPathInfo} pathInfo
 */
async function fixShutterstockImageToFitSize(pathInfo) {
    const shutterstockId = await getSourceId('shutterstock');
    const options = await getDownloadOptionsFromMedia(shutterstockId);
    const image = await loadUrlInImage(pathInfo.path, options);

    if (image.width === pathInfo.width && image.height === pathInfo.height) {
        // Image already has correct size
        return pathInfo.path;
    }

    const canvas = drawImgToCanvas(image, {
        width: pathInfo.width,
        height: pathInfo.height,
    });
    return canvas.toDataURL();
}

/**
 * Converts a Favorite media into a CMS media object we can use to add to a collection spot. Will use
 * the correct converter depending on the type of favorite
 *
 * @param {FavoriteImage} favorite
 * @returns {Promise<CMSMedia>}
 */
export const convertFavoriteToCMSMedia = async (favorite) => {
    const allSources = await getAllSources();
    const type = getMediaTypeForFavorite(favorite, allSources);

    if (type === 'Spot') {
        // @ts-ignore
        const { spot, photo } = favorite.data;
        return convertSpotMediaToCMSMedia(spot, photo);
    }

    if (type === 'PSMedia') {
        // @ts-ignore
        return convertPSMediaToCMSMedia(favorite.data);
    }

    if (type === 'Unsplash') {
        // @ts-ignore
        return convertUnsplashMediaToCMSMedia(favorite.data);
    }

    if (type === 'Shutterstock') {
        // @ts-ignore
        return convertShutterstockMediaToCMSMedia(favorite.data);
    }

    throw new Error(
        `convertFavoriteToCMSMedia: trying to convert a favorite with unknown type: ${type}`,
    );
};

/**
 * @param {PSMedia} psMedia
 * @param {CMSMedia} selectedMedia
 */
export const isPSMediaSelected = (psMedia, selectedMedia) => {
    if (!psMedia || !selectedMedia) {
        return false;
    }
    return psMedia.id + '' === selectedMedia.external_id;
};

/**
 * @param {PSSpot_v15_Photo} spotMedia
 * @param {CMSMedia} selectedMedia
 */
export const isSpotMediaSelected = (spotMedia, selectedMedia) => {
    if (!spotMedia || !selectedMedia) {
        return false;
    }
    return spotMedia.external_id + '' === selectedMedia.external_id;
};

/**
 * @param {UnsplashPhotoFromSearch} unsplashMedia
 * @param {CMSMedia} selectedMedia
 */
export const isUnsplashMediaSelected = (unsplashMedia, selectedMedia) => {
    if (!unsplashMedia || !selectedMedia) {
        return false;
    }
    return unsplashMedia.id + '' === selectedMedia.external_id;
};

/**
 * @param {ShutterstockPhotoFromSearch} shutterstockMedia
 * @param {CMSMedia} selectedMedia
 */
export const isShutterstockMediaSelected = (
    shutterstockMedia,
    selectedMedia,
) => {
    if (!shutterstockMedia || !selectedMedia) {
        return false;
    }
    return shutterstockMedia.id + '' === selectedMedia.external_id;
};

/**
 * @param {FavoriteImage} favorite
 * @param {MediaSelectorMediaType} favoriteType
 * @param {CMSMedia} selectedMedia
 */
export const isFavoriteSelected = (favorite, favoriteType, selectedMedia) => {
    if (!favorite || !selectedMedia) {
        return false;
    }
    switch (favoriteType) {
        case 'PSMedia':
            // @ts-ignore
            return isPSMediaSelected(favorite.data, selectedMedia);
        case 'Spot':
            // @ts-ignore
            return isSpotMediaSelected(favorite.data.photo, selectedMedia);
        case 'Unsplash':
            // @ts-ignore
            return isUnsplashMediaSelected(favorite.data, selectedMedia);
        case 'Shutterstock':
            // @ts-ignore
            return isShutterstockMediaSelected(favorite.data, selectedMedia);
        default:
            console.warn(
                `isFavoriteSelected: trying to check a favorite with unknown type: ${favoriteType}`,
            );
            return false;
    }
};

/**
 * Checks if two media items point to the same external media
 *
 * @param {CMSMedia} media1
 * @param {CMSMedia} media2
 */
export const isSameExternalMedia = (media1, media2) => {
    if (!media1 || !media2) {
        return false;
    }
    const isSameImage =
        media1.external_id === media2.external_id &&
        media1.source_id === media2.source_id;

    if (!isSameImage) {
        return false;
    }

    // Checking that all the paths are also the same
    for (const media1path of Object.keys(media1.paths)) {
        if (
            !isPathTheSame(media1.paths[media1path], media2.paths[media1path])
        ) {
            return false;
        }
    }

    return isSameImage;
};

/**
 * @param {FavoriteImage} favorite
 * @param {Source[]} allSources
 * @returns {MediaSelectorMediaType}
 */
export function getMediaTypeForFavorite(favorite, allSources) {
    const source = allSources.find((s) => s.id === favorite.source_id);
    if (!source) {
        return null;
    }
    if (source.name === 'polarsteps') {
        return 'PSMedia';
    }
    if (source.name === 'shutterstock') {
        return 'Shutterstock';
    }
    if (source.name === 'unsplash') {
        return 'Unsplash';
    }
    if (isSpotSource(source)) {
        return 'Spot';
    }
    return null;
}

/**
 * @param {CMSMediaPathInfo} a
 * @param {CMSMediaPathInfo} b
 */
function isPathTheSame(a, b) {
    if (!a || !b) {
        return false;
    }
    return a.height === b.height && a.width === b.width && a.path === b.path;
}

/**
 * @param {CMSMedia} media
 */
export function hasMediaCorrectAttribution(media) {
    return !!media.author_name;
}
