import React from 'react';
import classNames from 'classnames';
import './style.css';

/**
 *
 * @param {Object} props
 * @param {string} [props.label=null]
 * @param {React.ReactNode} [props.children=null]
 * @param {number} [props.length=null]
 * @param {number} [props.maxLength=null]
 * @param {number} [props.labelWidth=160]
 * @param {boolean} [props.alignLabelTop = false]
 * @param {boolean} [props.showSemicolon = true]
 * @param {string} [props.className = '']
 */
function FormField({
    label = null,
    children = null,
    length = null,
    maxLength = null,
    labelWidth = 160,
    alignLabelTop = false,
    showSemicolon = true,
    className = '',
}) {
    const showMaxLengthInfo =
        typeof length === 'number' && typeof maxLength === 'number';

    return (
        <div
            className={classNames('form-field', className, {
                'form-field-with-max-value': !!maxLength,
            })}
        >
            {label && (
                <div
                    className={classNames('form-label', {
                        'form-label-align-top': alignLabelTop,
                    })}
                    style={{
                        width: labelWidth,
                        minWidth: labelWidth,
                    }}
                >
                    {label}
                    {showSemicolon && ':'}
                </div>
            )}

            <div className="form-value">
                {children}
                {showMaxLengthInfo && (
                    <div
                        className={classNames('form-value__length', {
                            'form-value__length-error': length > maxLength,
                        })}
                    >
                        {length} / {maxLength}
                    </div>
                )}
            </div>
        </div>
    );
}

export default FormField;
