import React, { useEffect, useState } from 'react';
import { Typography, Button, Alert } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import { useDispatch } from 'react-redux';
import { useSelector } from 'utils/use-selector';
import { getCurrentGuide } from 'store/guide-editor/selectors';
import StoryItemEditor from 'components/GuideEditor/StoryItemEditor';
import {
    isLoadingStoriesForGuide,
    getErrorLoadingStoriesForGuide,
    isSavingStoryItems as isSavingStoryItemsSelector,
    getStoriesForGuide,
} from 'store/story-items/selectors';
import {
    getCurrentStoriesBeingEdited,
    haveStoriesChanged as haveStoriesChangedSelector,
    areStoriesComplete as areStoriesCompleteSelector,
} from 'store/guide-editor/selectors';
import {
    initGuideStories,
    saveGuideStories,
    resetGuideStories,
    notifyGuideStoriesChange,
} from 'store/guide-editor/actions';
import Loading from 'components/Loading';
import { useMemo } from 'react';
import { getNonBoughtMediaFromList } from 'utils/stock-images-utils';
import { isSameExternalMedia } from 'utils/media-utils';
import AddStockPhotoWarningModal from 'components/StockPhotoWarning';
import { STORY_COVER } from 'constants/media-sizes.constants';
import { addCMSMedia } from 'api/cms-media.api';
import { times } from 'lodash';

const { Title } = Typography;
/**
 *
 * @param {Object} props
 * @param {() => void} props.onClickDiscard
 */
function GuideEditorStory({ onClickDiscard }) {
    const dispatch = useDispatch();
    const currentGuide = useSelector(getCurrentGuide);
    const guideId = useMemo(() => currentGuide?.id, [currentGuide]);
    useEffect(() => {
        if (guideId) {
            dispatch(initGuideStories(guideId));
        }
        return () => {
            dispatch(resetGuideStories());
        };
    }, [guideId, dispatch]);

    const currentStories = useSelector(getCurrentStoriesBeingEdited);
    const oldStories = useSelector((state) =>
        getStoriesForGuide(state, guideId),
    );
    const isLoading = useSelector((state) =>
        isLoadingStoriesForGuide(state, guideId),
    );
    const errorLoading = useSelector((state) =>
        getErrorLoadingStoriesForGuide(state, guideId),
    );
    const isSavingStoryItems = useSelector(isSavingStoryItemsSelector);
    const haveStoriesChanged = useSelector(haveStoriesChangedSelector);
    const areStoriesComplete = useSelector(areStoriesCompleteSelector);
    const [
        isAddingStockPhotoWarningShown,
        setIsAddingStockPhotoWarningShown,
    ] = useState(false);
    /** @type {[CMSMedia[], React.Dispatch<CMSMedia[]>]} */
    const [photosToPurchase, setPhotosToPurchase] = useState(null);
    const [isSavingMedia, setIsSavingMedia] = useState(false);
    /** @type {[number, React.Dispatch<number>]}*/
    const [storyToScrollTo, setStoryToScrollTo] = useState(null); // We will increment this number when adding a story, and when that change is detected a scroll will be done to the last story
    useEffect(() => {
        if (storyToScrollTo) {
            const allStories = document.body.querySelectorAll(
                '.js-story-item-editor',
            );
            if (!allStories) {
                return;
            }
            allStories[allStories.length - 1]?.scrollIntoView({
                behavior: 'smooth',
            });
        }
    }, [storyToScrollTo]);

    const addStory = () => {
        const newStory = getEmptyStory(currentGuide, currentStories);
        const newStories = currentStories.concat([newStory]);
        dispatch(notifyGuideStoriesChange(newStories));
        setStoryToScrollTo(1 + (storyToScrollTo || 0));
    };

    /**
     * Patches many story fields at the same time and dispatches them to the store
     *
     * @param {StoryFieldChange[]} changes
     */
    const patchStoriesFields = (changes) => {
        let stories = currentStories;
        for (const change of changes) {
            stories = applyPatchToStories(stories, change);
        }
        dispatch(notifyGuideStoriesChange(stories));
    };

    /**
     * Patches a single story field and dispatches it to the store
     *
     * @param {StoryFieldChange} change
     */
    const patchStoryField = (change) => {
        patchStoriesFields([change]);
    };

    const moveStoryUp = (storyId) => {
        const storyToMove = currentStories.find(
            (story) => story.id === storyId,
        );
        if (storyToMove.order <= 0) {
            return;
        }
        const prevStory = currentStories.find(
            (story) => story.order === storyToMove.order - 1,
        );

        patchStoriesFields([
            {
                storyId: prevStory.id,
                fieldName: 'order',
                newValue: storyToMove.order,
            },
            {
                storyId: storyToMove.id,
                fieldName: 'order',
                newValue: storyToMove.order - 1,
            },
        ]);
    };

    const moveStoryDown = (storyId) => {
        const storyToMove = currentStories.find(
            (story) => story.id === storyId,
        );
        if (storyToMove.order >= currentStories.length - 1) {
            return;
        }
        const nextStory = currentStories.find(
            (story) => story.order === storyToMove.order + 1,
        );

        patchStoriesFields([
            {
                storyId: nextStory.id,
                fieldName: 'order',
                newValue: storyToMove.order,
            },
            {
                storyId: storyToMove.id,
                fieldName: 'order',
                newValue: storyToMove.order + 1,
            },
        ]);
    };

    const removeStory = (storyId) => {
        const newStories = currentStories.filter(
            (story) => story.id !== storyId,
        );
        dispatch(notifyGuideStoriesChange(newStories));
    };

    /**
     * Checks if the guide is published, and the editor is adding stories with stock photos that will be bought.
     *
     * @returns {Promise<{shouldShow: boolean, stories: StoryItem[]}>} When it returns true, the warning should be show, and a list of stories with media that needs licensing will be returned
     */
    const shouldShowWarningAddingStockPhotos = async () => {
        if (currentGuide.publish_status.name !== 'published') {
            return { shouldShow: false, stories: null };
        }

        const storiesWithChangedMedia = currentStories.filter((story) => {
            if (!story.cms_media) {
                return false;
            }
            const oldStory = oldStories.find(
                (oldStory) => oldStory.id === story.id,
            );
            if (!oldStory) {
                return true;
            }
            return !isSameExternalMedia(story.cms_media, oldStory.cms_media);
        });
        const allNonBoughtStockImages = await getNonBoughtMediaFromList(
            storiesWithChangedMedia.map((story) => story.cms_media),
        );
        const storiesWithNonBoughtStockImages = storiesWithChangedMedia.filter(
            (story) => {
                return !!allNonBoughtStockImages.find((image) =>
                    isSameExternalMedia(image, story.cms_media),
                );
            },
        );
        return {
            shouldShow: !!allNonBoughtStockImages.length,
            stories: storiesWithNonBoughtStockImages,
        };
    };

    /**
     *
     * @param {CMSMedia[]} alreadySavedMedia
     */
    const dispatchSavedStories = async (alreadySavedMedia) => {
        const {
            storiesWithMediaAlreadySaved,
            storiesWithMediaToBeSaved,
        } = currentStories.reduce(
            (acc, story) => {
                const correspondingMedia = alreadySavedMedia.find((media) =>
                    isSameExternalMedia(media, story.cms_media),
                );
                if (!correspondingMedia) {
                    return {
                        ...acc,
                        storiesWithMediaToBeSaved: [
                            ...acc.storiesWithMediaToBeSaved,
                            story,
                        ],
                    };
                }
                const updatedStory = {
                    ...story,
                    cms_media: correspondingMedia,
                };
                return {
                    ...acc,
                    storiesWithMediaAlreadySaved: [
                        ...acc.storiesWithMediaAlreadySaved,
                        updatedStory,
                    ],
                };
            },
            { storiesWithMediaAlreadySaved: [], storiesWithMediaToBeSaved: [] },
        );
        setIsSavingMedia(true);
        for (const story of storiesWithMediaToBeSaved) {
            const oldStory = oldStories.find(
                (oldStory) => oldStory.id === story.id,
            );
            if (!isSameExternalMedia(oldStory?.cms_media, story.cms_media)) {
                const addedMedia = await addCMSMedia(
                    story.cms_media,
                    STORY_COVER,
                );
                story.cms_media = addedMedia;
            }
        }

        setIsSavingMedia(false);
        dispatch(
            saveGuideStories(guideId, [
                ...storiesWithMediaAlreadySaved,
                ...storiesWithMediaToBeSaved,
            ]),
        );
    };

    const save = async () => {
        const {
            shouldShow,
            stories,
        } = await shouldShowWarningAddingStockPhotos();
        if (shouldShow) {
            setPhotosToPurchase(stories.map((story) => story.cms_media));
            setIsAddingStockPhotoWarningShown(true);
            return;
        }
        dispatchSavedStories([]);
    };

    if (!currentGuide) {
        return null;
    }

    return (
        <>
            <div className="line-center">
                <Title level={4}>
                    Story items <span>({currentStories.length})</span>
                </Title>
                <div className="grow-full-flex"></div>
                {!isLoading &&
                    (!currentStories.length ? (
                        <Button
                            type="primary"
                            size="large"
                            icon={<PlusOutlined />}
                            onClick={() => {
                                let newStories = [];
                                times(6, () => {
                                    newStories.push(
                                        getEmptyStory(
                                            currentGuide,
                                            currentStories,
                                        ),
                                    );
                                });
                                dispatch(
                                    notifyGuideStoriesChange(
                                        currentStories.concat(newStories),
                                    ),
                                );
                                setStoryToScrollTo(1 + (storyToScrollTo || 0));
                            }}
                        >
                            Preload six stories
                        </Button>
                    ) : (
                        <Button
                            type="primary"
                            size="large"
                            icon={<PlusOutlined />}
                            onClick={addStory}
                        >
                            Add new item
                        </Button>
                    ))}
            </div>

            {isLoading ||
                (isSavingStoryItems && (
                    <div className="full-page-tabs-loading">
                        <Loading />
                    </div>
                ))}

            {errorLoading && (
                <div className="full-page-tabs-error">
                    <Alert
                        className="grow-full-flex"
                        message="There was an error loading story items"
                        description={errorLoading}
                        type="error"
                        showIcon
                    />
                </div>
            )}
            {!isLoading && !currentStories.length && <div>No stories yet</div>}
            {currentStories.map((story, index) => (
                <div key={story.id} className="mt-ml">
                    <StoryItemEditor
                        story={story}
                        index={index}
                        totalStories={currentStories.length}
                        guide={currentGuide}
                        onFieldChanged={(storyId, fieldName, newValue) =>
                            patchStoryField({
                                storyId,
                                fieldName,
                                newValue,
                            })
                        }
                        onMoveUp={moveStoryUp}
                        onMoveDown={moveStoryDown}
                        onRemove={removeStory}
                    />
                </div>
            ))}
            {!isLoading && (currentStories?.length || 0) > 2 && (
                <div className="line-center mt-ml">
                    <div className="grow-full-flex"></div>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        onClick={addStory}
                    >
                        Add new item
                    </Button>
                </div>
            )}
            {isAddingStockPhotoWarningShown && (
                <AddStockPhotoWarningModal
                    photosToPurchase={photosToPurchase}
                    onAdd={(purchasedPhotos) => {
                        setIsAddingStockPhotoWarningShown(false);
                        dispatchSavedStories(purchasedPhotos);
                    }}
                    onCancel={() => {
                        setIsAddingStockPhotoWarningShown(false);
                    }}
                    sizesToUpload={STORY_COVER}
                />
            )}
            <div className="full-page-tabs-footer">
                <div className="max-content-width">
                    <div className="grow-full-flex"></div>

                    <Button size="large" onClick={onClickDiscard}>
                        Cancel
                    </Button>

                    <Button
                        size="large"
                        type="primary"
                        loading={isSavingStoryItems || isSavingMedia}
                        onClick={() => save()}
                        disabled={!haveStoriesChanged || !areStoriesComplete}
                    >
                        Save changes
                    </Button>
                </div>
            </div>
        </>
    );
}

/**
 *
 * @param {Guide} guide
 * @param {StoryItem[]} otherStories
 * @returns {StoryItem}
 */
function getEmptyStory(guide, otherStories) {
    return {
        id: uuid(),
        guide_id: guide.id,
        spot: null,
        cms_media: null,
        tagline: '',
        order: otherStories.length,
        editor_notes: '',
    };
}
/**
 * @typedef {object} StoryFieldChange storyId, fieldName, newValue
 * @property {Id} storyId
 * @property {string} fieldName
 * @property {any} newValue
 */

/**
 * Applies a field patch to stories, returning a copy of them with the modified story
 *
 * @param {StoryItem[]} stories
 * @param {StoryFieldChange} change
 * @returns {StoryItem[]}
 */
function applyPatchToStories(stories, change) {
    const storyToChange = stories.find((story) => story.id === change.storyId);
    const changedStory = {
        ...storyToChange,
        [change.fieldName]: change.newValue,
    };
    return stories.map((story) => {
        if (story.id === changedStory.id) {
            return changedStory;
        }
        return story;
    });
}

export default GuideEditorStory;
