import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import MediaSelectorPreview from './MediaSelectorPreview';
import MediaSelectorModal from './MediaSelectorModal';
import MediaCropperModal from './MediaCropperModal';
import { isImage, isVideo } from 'constants/media-types.constants';
import { getMediaTypeText } from 'utils/media-utils';
import { useStateUpdatedByProp } from 'utils/use-state-updated-by-prop';
import './style.css';

const MODAL_STATES = {
    HIDDEN: 'HIDDEN',
    CHOOSE_MEDIA: 'CHOOSE_MEDIA',
    CROP_MEDIA: 'CROP_MEDIA',
};

/**
 * @param {Object} props
 * @param {number} props.ratio
 * @param {PSLocation} [props.location]
 * @param {PSSpot_v15_Minimal} [props.spot]
 * @param {CollectionSpot} [props.collectionSpot]
 * @param {CMSMedia} [props.media]
 * @param {Id} props.guideId
 * @param {boolean} [props.includeVideos]
 * @param {(media: CMSMedia) => void} props.onSelect
 * @param {() => void} props.onDelete
 * @param {boolean} [props.openSelectorOnInit=false]
 */
function MediaSelector({
    ratio,
    location,
    spot,
    collectionSpot,
    media,
    guideId,
    includeVideos = false,
    onSelect,
    onDelete,
    openSelectorOnInit: openSelectorOnInitProp = false,
}) {
    const [openSelectorOnInit, setOpenSelectorOnInit] = useStateUpdatedByProp(
        openSelectorOnInitProp,
    );
    const [selectedMedia, setSelectedMedia] = useState(media);
    const [modalState, setModalState] = useState(MODAL_STATES.HIDDEN);
    const [crop, setCrop] = useState({ aspect: ratio });

    useEffect(() => {
        if (openSelectorOnInit) {
            // When opening the modal automatically, always show the selector first
            setModalState(MODAL_STATES.CHOOSE_MEDIA);
            setOpenSelectorOnInit(false);
        }
    }, [openSelectorOnInit, setOpenSelectorOnInit]);

    const closeModal = () => {
        // Reset state
        setModalState(MODAL_STATES.HIDDEN);
        setCrop({ aspect: ratio });
    };

    const onOKClicked = () => {
        if (!selectedMedia) {
            return;
        }

        // If it is an image, crop it
        if (
            isImage(selectedMedia) &&
            modalState === MODAL_STATES.CHOOSE_MEDIA
        ) {
            setModalState(MODAL_STATES.CROP_MEDIA);
            return;
        }

        if (isVideo(selectedMedia) || modalState === MODAL_STATES.CROP_MEDIA) {
            onSelect({
                ...selectedMedia,
                $$cropInfo: crop,
            });
            closeModal();
        }
    };

    return (
        <>
            <div className="media-selector">
                <MediaSelectorPreview
                    ratio={ratio}
                    media={media}
                    onEditClick={() =>
                        setModalState(
                            media
                                ? MODAL_STATES.CROP_MEDIA
                                : MODAL_STATES.CHOOSE_MEDIA,
                        )
                    }
                    onDeleteClick={onDelete}
                    disabled={!location}
                    disabledText="Please select a location first"
                />
                <Modal
                    className={classNames(
                        'full-screen-modal',
                        'media-selector__modal',
                        {
                            'has-back-button':
                                modalState === MODAL_STATES.CROP_MEDIA,
                        },
                    )}
                    title={
                        <MediaSelectorModalTitle
                            modalState={modalState}
                            onBack={() =>
                                setModalState(MODAL_STATES.CHOOSE_MEDIA)
                            }
                            onCloseModal={closeModal}
                            selectedMedia={selectedMedia}
                            onOKClicked={onOKClicked}
                        />
                    }
                    open={modalState !== MODAL_STATES.HIDDEN}
                    onOk={closeModal}
                    onCancel={closeModal}
                    closable={false}
                    footer={null}
                    destroyOnClose
                >
                    {/* We just hide / show the media selector part of the modal so
                        if we come back to it after cropping we don't need to scroll all the way down again */}
                    <MediaSelectorModal
                        shouldShow={modalState === MODAL_STATES.CHOOSE_MEDIA}
                        includeVideos={includeVideos}
                        selectedMedia={selectedMedia}
                        guideId={guideId}
                        onSelect={(newSelectedMedia) =>
                            setSelectedMedia(newSelectedMedia)
                        }
                        location={location}
                        spot={spot}
                        collectionSpot={collectionSpot}
                    />
                    {modalState === MODAL_STATES.CROP_MEDIA && (
                        <MediaCropperModal
                            selectedMedia={selectedMedia}
                            ratio={ratio}
                            crop={crop}
                            onCropChange={({ crop, media }) => {
                                setCrop(crop);
                                setSelectedMedia(media);
                            }}
                            onError={() => {
                                setModalState(MODAL_STATES.CHOOSE_MEDIA);
                            }}
                            spot={spot}
                        />
                    )}
                </Modal>
            </div>
        </>
    );
}

function MediaSelectorModalTitle({
    modalState,
    onBack,
    onCloseModal,
    selectedMedia,
    onOKClicked,
}) {
    switch (modalState) {
        case MODAL_STATES.CHOOSE_MEDIA:
            return (
                <div className="line-center">
                    <span>Media manager</span>
                    <div className="grow-full-flex" />
                    <Button key="back" onClick={onCloseModal}>
                        Cancel
                    </Button>
                    <Button
                        className="ml-s"
                        key="submit"
                        type="primary"
                        disabled={!selectedMedia}
                        onClick={onOKClicked}
                    >
                        Select {getMediaTypeText(selectedMedia)}
                    </Button>
                </div>
            );
        case MODAL_STATES.CROP_MEDIA:
            return (
                <div className="line-center">
                    <div className="grow-full-flex">
                        <Button
                            type="link"
                            icon={<ArrowLeftOutlined />}
                            style={{ paddingLeft: 0 }}
                            onClick={onBack}
                        >
                            Media overview
                        </Button>
                        <div>Crop image</div>
                    </div>
                    <Button key="back" onClick={onCloseModal}>
                        Cancel
                    </Button>
                    <Button
                        className="ml-s"
                        key="submit"
                        type="primary"
                        disabled={!selectedMedia}
                        onClick={onOKClicked}
                    >
                        Apply crop
                    </Button>
                </div>
            );
        default:
            return null;
    }
}

export default MediaSelector;
