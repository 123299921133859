import { isSameObject } from 'utils/compare';
import memoizeOne from 'memoize-one';

/**
 * @typedef {object} StorySetChanges
 * @property {StoryItem[]} newStories Stories that have been added
 * @property {StoryItem[]} deletedStories Stories that have been deleted
 * @property {StoryItem[]} editedStories Stories that have been changed
 */

/**
 * Gets the changes between two sets of story items
 *
 * @param {StoryItem[]} current
 * @param {StoryItem[]} original
 * @returns {StorySetChanges}
 */
export const getChangesBetweenStorySets = memoizeOne(
    getChangesBetweenStorySetsNotMemo,
);

/**
 * Gets the changes between two sets of story items
 *
 * @param {StoryItem[]} current
 * @param {StoryItem[]} original
 * @returns {StorySetChanges}
 */
function getChangesBetweenStorySetsNotMemo(current, original) {
    const newStories = current.filter(
        (story) => !isStoryContained(story, original),
    );
    const deletedStories = original.filter(
        (story) => !isStoryContained(story, current),
    );
    const editedStories = current.filter((story) =>
        hasStoryChanged(story, original),
    );

    return { newStories, deletedStories, editedStories };
}

/**
 * Checks if a story is contained in an array of stories (just checking its id)
 *
 * @param {StoryItem} story
 * @param {StoryItem[]} stories
 * @returns {boolean}
 */
function isStoryContained(story, stories) {
    return !!findOriginal(story, stories);
}

/**
 * Checks if a story has changed compared to the original (also checks if there was an original one)
 *
 * @param {StoryItem} story
 * @param {StoryItem[]} originals list of original story items, if the story is not tehre it means that it is new, not changed
 * @returns {boolean}
 */
function hasStoryChanged(story, originals) {
    const originalStory = originals.find(
        (eachStory) => story.id === eachStory.id,
    );
    if (!originalStory) {
        return false;
    }
    return (
        story.order !== originalStory.order ||
        story.tagline !== originalStory.tagline ||
        !isSameObject(story.spot, originalStory.spot) ||
        !isSameObject(story.cms_media, originalStory.cms_media) ||
        story.editor_notes !== originalStory.editor_notes
    );
}

/**
 * Gets the original story related (same id) to a story from a list
 *
 * @param {StoryItem} story
 * @param {StoryItem[]} originals
 * @returns {StoryItem}
 */
export const findOriginal = (story, originals) => {
    return originals.find((eachStory) => story.id === eachStory.id);
};
