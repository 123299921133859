export const GUIDE_COVER_RATIO = 16 / 9;

/** @type {MediaSize[]} */
export const GUIDE_COVER = [
    {
        maxWidth: 4000,
        maxHeight: Math.round(4000 / GUIDE_COVER_RATIO),
        quality: 0.9,
    },
    {
        maxWidth: 3000,
        maxHeight: Math.round(3000 / GUIDE_COVER_RATIO),
        quality: 0.8,
    },
    {
        maxWidth: 1500,
        maxHeight: Math.round(1500 / GUIDE_COVER_RATIO),
        quality: 0.8,
    },
    {
        maxWidth: 1000,
        maxHeight: Math.round(1000 / GUIDE_COVER_RATIO),
        quality: 0.9,
    },
    {
        maxWidth: 400,
        maxHeight: Math.round(400 / GUIDE_COVER_RATIO),
        quality: 0.9,
    },
];

export const STORY_COVER_RATIO = 9 / 16;

/** @type {MediaSize[]} */
export const STORY_COVER = [
    {
        maxWidth: Math.round(4000 * STORY_COVER_RATIO),
        maxHeight: 4000,
        quality: 0.9,
    },
    {
        maxWidth: Math.round(2000 * STORY_COVER_RATIO),
        maxHeight: 2000,
        quality: 0.9,
    },
    {
        maxWidth: Math.round(1000 * STORY_COVER_RATIO),
        maxHeight: 1000,
        quality: 0.9,
    },
    {
        maxWidth: Math.round(400 * STORY_COVER_RATIO),
        maxHeight: 400,
        quality: 0.9,
    },
];

export const SPOT_MEDIA_RATIO = 16 / 9;

/** @type {MediaSize[]} */
export const SPOT_MEDIA = [
    {
        maxWidth: 4000,
        maxHeight: Math.round(4000 / SPOT_MEDIA_RATIO),
        quality: 0.9,
    },
    {
        maxWidth: 1000,
        maxHeight: Math.round(1000 / SPOT_MEDIA_RATIO),
        quality: 0.9,
    },
    {
        maxWidth: 400,
        maxHeight: Math.round(400 / SPOT_MEDIA_RATIO),
        quality: 0.9,
    },
];

/** @type {MediaSize[]} */
// Media shown in the spot detail part, they don't need to big hi res
export const SPOT_DETAIL_MEDIA = [
    {
        maxWidth: 1000,
        maxHeight: 1000,
        quality: 0.9,
    },
    {
        maxWidth: 400,
        maxHeight: 400,
        quality: 0.9,
    },
];
