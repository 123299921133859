import React from 'react';
import classNames from 'classnames';
import { Button, Tooltip } from 'antd';
import {
    FileImageTwoTone,
    EditOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import AspectRatio from 'components/AspectRatio';
import { getMediaWithSize } from 'utils/media-utils';
import './style.css';

/**
 *
 * @param {Object} props
 * @param {number} [props.ratio=16 / 9]
 * @param {CMSMedia} props.media
 * @param {() => void} props.onEditClick
 * @param {() => void} props.onDeleteClick
 * @param {boolean} [props.disabled=false]
 * @param {string} [props.disabledText='']
 */
function MediaSelectorPreview({
    ratio = 16 / 9,
    media,
    onEditClick,
    onDeleteClick,
    disabled = false,
    disabledText = '',
}) {
    const closestSizedMedia = getMediaWithSize(media, 600);

    const onEditClickWrapped = () => {
        if (disabled) {
            return;
        }
        onEditClick();
    };

    return (
        <AspectRatio
            ratio={ratio}
            className={classNames('media-selector-preview', {
                'can-select-image': !disabled,
            })}
        >
            {!media && (
                <div
                    className="media-selector-preview__img"
                    onClick={onEditClickWrapped}
                >
                    <FileImageTwoTone className="media-selector-preview__img-icon" />
                </div>
            )}
            {media && (
                <div
                    onClick={onEditClickWrapped}
                    className="media-selector-preview__img"
                    style={{
                        ...getStyleFromMedia(media),
                        backgroundImage: `url(${closestSizedMedia.path})`,
                    }}
                ></div>
            )}
            <div className="floating-buttons">
                <Tooltip
                    placement="left"
                    title={disabled ? disabledText : null}
                >
                    <Button
                        icon={<EditOutlined />}
                        disabled={disabled}
                        onClick={onEditClickWrapped}
                    />
                </Tooltip>

                {media && (
                    <Button
                        className="ml-sm"
                        danger={true}
                        icon={<DeleteOutlined />}
                        onClick={onDeleteClick}
                    />
                )}
            </div>
        </AspectRatio>
    );
}

/**
 * Gets the style to apply to the img element so it fits the crop of a media (if any)
 *
 * @param {CMSMedia} media
 * @returns {*}
 */
function getStyleFromMedia(media) {
    if (
        !media.$$cropInfo ||
        !media.$$cropInfo.originalWidth ||
        !media.$$cropInfo.originalHeight ||
        !media.$$cropInfo.width ||
        !media.$$cropInfo.height
    ) {
        return {};
    }

    const position = {
        x: '0%',
        y: '0%',
    };

    if (media.$$cropInfo.originalWidth !== media.$$cropInfo.width) {
        position.x =
            (100 * media.$$cropInfo.x) /
                (media.$$cropInfo.originalWidth - media.$$cropInfo.width) +
            '%';
    }

    if (media.$$cropInfo.originalHeight !== media.$$cropInfo.height) {
        position.y =
            (100 * media.$$cropInfo.y) /
                (media.$$cropInfo.originalHeight - media.$$cropInfo.height) +
            '%';
    }

    const size =
        (100 * media.$$cropInfo.originalWidth) / media.$$cropInfo.width;

    return {
        backgroundPosition: `${position.x} ${position.y}`,
        backgroundSize: `${size}%`,
    };
}

export default MediaSelectorPreview;
