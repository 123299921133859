export const START_ADDING_GUIDE = 'START_ADDING_GUIDE';
export const STOP_ADDING_GUIDE = 'STOP_ADDING_GUIDE';
export const PATCH_GUIDE_FIELD = 'PATCH_GUIDE_FIELD';
export const START_SAVING_NEW_GUIDE = 'START_SAVING_NEW_GUIDE';
export const SUCCESS_SAVING_NEW_GUIDE = 'SUCCESS_SAVING_NEW_GUIDE';
export const FAILED_SAVING_NEW_GUIDE = 'FAILED_SAVING_NEW_GUIDE';
export const INIT_EDITED_GUIDE = 'INIT_EDITED_GUIDE';
export const STOP_EDITING_GUIDE = 'STOP_EDITING_GUIDE';
export const START_PATCHING_GUIDE = 'START_PATCHING_GUIDE';
export const SUCCESS_PATCHING_GUIDE = 'SUCCESS_PATCHING_GUIDE';
export const FAILED_PATCHING_GUIDE = 'FAILED_PATCHING_GUIDE';
export const NOTIFY_SPOT_COLLECTION_EDITOR_SHOWN =
    'NOTIFY_SPOT_COLLECTION_EDITOR_SHOWN';
export const SET_CURRENT_GUIDE_STORIES = 'SET_CURRENT_GUIDE_STORIES';
export const SET_DEFAULT_NEW_COLLECTION = 'SET_DEFAULT_NEW_COLLECTION';
export const UPDATE_CURRENT_GUIDE_FIELD = 'UPDATE_CURRENT_GUIDE_FIELD';
