import {
    STARTED_LOADING_STORY_ITEMS_FOR_GUIDE,
    FAILED_LOADING_STORY_ITEMS_FOR_GUIDE,
    SUCCESS_LOADING_STORY_ITEMS_FOR_GUIDE,
    STARTED_SAVING_STORY_ITEMS,
    FAILED_SAVING_STORY_ITEMS,
    SUCCESS_SAVING_STORY_ITEMS,
} from './action-types';
import {
    getStoriesForGuide as getStoriesForGuideFromDB,
    addStory,
    deleteStory,
    patchStory,
} from 'api/story-items.api';
import { parse } from 'utils/error-parser';
import { notification } from 'antd';
import { getStoriesForGuide } from './selectors';
import {
    getChangesBetweenStorySets,
    findOriginal,
} from 'utils/story-item-utils';

/**
 * Loads the story items associated to a guide
 *
 * @param {Id} guideId
 * @returns {object} The action to be dispatched
 */
export const loadStoriesForGuide = (guideId) => {
    return async (dispatch, getState) => {
        dispatch({ type: STARTED_LOADING_STORY_ITEMS_FOR_GUIDE, guideId });
        try {
            const payload = await getStoriesForGuideFromDB(guideId);
            dispatch({
                type: SUCCESS_LOADING_STORY_ITEMS_FOR_GUIDE,
                guideId,
                payload,
            });
            return getStoriesForGuide(getState(), guideId);
        } catch (error) {
            const errorText = parse(error);
            dispatch({
                type: FAILED_LOADING_STORY_ITEMS_FOR_GUIDE,
                guideId,
                error: errorText,
            });
        }
    };
};

/**
 * Saves the stories that have changed
 *
 * @param {Id} guideId
 * @param {StoryItem[]} current
 * @param {StoryItem[]} original
 * @returns {object} The action to be dispatched
 */
export const saveChangedStories = (guideId, current, original) => {
    return async (dispatch) => {
        const {
            newStories,
            deletedStories,
            editedStories,
        } = getChangesBetweenStorySets(current, original);

        dispatch({ type: STARTED_SAVING_STORY_ITEMS });
        const willAddStories = newStories.map((newStory) => addStory(newStory));
        const willDeleteStories = deletedStories.map((deletedStory) =>
            deleteStory(deletedStory),
        );
        const willPatchStories = editedStories.map((editedStory) =>
            patchStory(editedStory, findOriginal(editedStory, original)),
        );

        try {
            await Promise.all([
                ...willAddStories,
                ...willDeleteStories,
                ...willPatchStories,
            ]);
            await dispatch(loadStoriesForGuide(guideId));
            dispatch({ type: SUCCESS_SAVING_STORY_ITEMS });
        } catch (error) {
            const errorText = parse(error);
            dispatch({
                type: FAILED_SAVING_STORY_ITEMS,
                error: errorText,
            });
            notification.error({
                message: 'Error saving story items',
                description: errorText,
            });
            throw error;
        }
    };
};
