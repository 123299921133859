import React from 'react';
import { Modal, Tag } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Tabs } from 'components/Tabs';
import { useSelector } from 'react-redux';
import {
    isAddingGuide,
    hasGuideChanged,
    haveStoriesChanged,
} from 'store/guide-editor/selectors';
import { hasUnsavedChanges } from 'store/misc-selectors';
import { useHistory, useLocation } from 'react-router-dom';
import GuideEditorBasicInfo from './GuideEditorBasicInfo';
import GuideEditorStory from './GuideEditorStory';
import GuideEditorCollections from './GuideEditorCollections';
import GuideEditorMap from './GuideEditorMap';
import GuideEditorTips from './GuideEditorTips';

import './style.css';
import GuideEditorSettings from './GuideEditorSettings';
import { useFeatureFlag } from 'feature-flags';

const { confirm } = Modal;

function GuideEditor() {
    const history = useHistory();
    const addingGuide = useSelector(isAddingGuide);
    const hasBasicInfoChanged = useSelector(hasGuideChanged);
    const hasStoryTabChanged = useSelector(haveStoriesChanged);
    const unsavedChanges = useSelector(hasUnsavedChanges);
    const [ENABLE_MAP_TAB] = useFeatureFlag('ENABLE_MAP_TAB');

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const tabField = query.get('tab');

    const onClickDiscard = () => {
        if (!unsavedChanges) {
            history.push('/guides');
            return;
        }
        confirm({
            title: 'Discard changes',
            content:
                'Are you sure you want to discard the changes you made in the guide? All changes will be lost.',
            onOk() {
                history.push('/guides');
            },
            okText: 'Discard',
            okButtonProps: {
                danger: true,
            },
            cancelText: 'Cancel',
        });
    };

    let tabsToShow = [
        {
            name: (
                <span>
                    Basic info{' '}
                    {hasBasicInfoChanged && (
                        <EditOutlined className="tab-pane-icon" />
                    )}
                </span>
            ),
            key: 'basicInfo',
            content: <GuideEditorBasicInfo onClickDiscard={onClickDiscard} />,
            hasFooter: true,
        },
    ];
    if (!addingGuide) {
        tabsToShow = [
            {
                name: <>Collections</>,
                key: 'collections',
                content: <GuideEditorCollections />,
                hasFooter: false,
            },
            ...tabsToShow,
            {
                name: (
                    <span>
                        Story{' '}
                        {hasStoryTabChanged && (
                            <EditOutlined className="tab-pane-icon" />
                        )}
                    </span>
                ),
                key: 'story',
                content: <GuideEditorStory onClickDiscard={onClickDiscard} />,
                hasFooter: true,
            },
            ...(ENABLE_MAP_TAB
                ? [
                      {
                          name: (
                              <span>
                                  Map <Tag color="blue">BETA</Tag>
                              </span>
                          ),
                          key: 'map',
                          content: <GuideEditorMap />,
                          hasFooter: false,
                      },
                  ]
                : []),
            {
                name: <>Settings</>,
                key: 'settings',
                content: <GuideEditorSettings />,
                hasFooter: false,
            },
            {
                name: <>Tips</>,
                key: 'tips',
                content: <GuideEditorTips />,
                hasFooter: false,
            },
        ];
    }

    return (
        <Tabs
            defaultActiveKey={addingGuide ? 'basicInfo' : 'collections'}
            type="full-page"
            tabs={tabsToShow}
            activeKey={tabField || undefined}
            onChange={(newActiveKey) => {
                history.push({
                    pathname: history.location.pathname,
                    search: `?tab=${newActiveKey}`,
                });
            }}
        ></Tabs>
    );
}

export default GuideEditor;
