import React from 'react';
import './style.css';

/**
 *
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {number} props.ratio
 * @param {string} props.className
 */
function AspectRatio({ children, ratio, className = '' }) {
    return (
        <div className={className}>
            <div
                className="aspect-ratio-before"
                style={{
                    paddingTop: `${(1 / ratio) * 100}%`,
                }}
            />
            {children}
            <div className="aspect-ratio-after" />
        </div>
    );
}

export default AspectRatio;
